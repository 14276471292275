import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formActions } from '../../../Store/form'
import { deleteImageFromS3, getImageFromS3 } from '../../../Utils/S3Utils'
import Hotspots from './Hotspots'
import toast from 'react-hot-toast'
import { TfiAlignJustify, TfiCheck, TfiClose, TfiTrash } from 'react-icons/tfi'
import { tourActions } from '../../../Store/tour'
function Scenes({scene,sceneId}) {
  const form = useSelector(state => state.form)
  const isActive = (form.activeScene == sceneId)
  const [imageUrl,setImageUrl] = useState("")
  const[showHotspot,setShowHotspot] = useState(false)
  const [loading,setLoading] = useState(true)
  const dispatch = useDispatch()
  useEffect(()=>{
    getImageByScene()
  },[])

  async function toggleScene(e){
    if(e.target.id == "showHotspot" && form.activeScene == sceneId){
      setShowHotspot(!showHotspot)
    }
    if(form.activeScene != sceneId){
      dispatch(formActions.setActiveScene(sceneId))
      dispatch(formActions.setActiveHotspot(-1))
    }
  }
  async function getImageByScene(){
    const url = await getImageFromS3(scene.imageName,true)
    setImageUrl(url)
  }
  function deleteScene(e){
    toast(
      (t) => (
        <p className='text-lg flex flex-col'>
          <div>Do you want to delete <span className='text-blue-500'>{" " + scene.sceneName+ " "}</span></div>
          <div className='w-full flex items-center justify-center mt-2'>
            <button className='bg-green-500 mr-1 rounded text-white ' onClick={async ()=>{
              dispatch(formActions.setUpdated(true))
              await deleteImageFromS3(scene.imageName)
              dispatch(tourActions.removeSceneById(sceneId))
              toast.dismiss(t.id)
              dispatch(formActions.reset())
            }}><TfiCheck className='w-6 h-6'/></button>
            <button className='text-white ml-1 rounded bg-red-500' onClick={() => toast.dismiss(t.id)}><TfiClose className='w-6 h-6'/></button>
          </div>
        </p>
      ),
      {
        icon: <TfiTrash className='w-10 h-10'/>,
      }
    );
  }
  return (
    <div className={`flex ${showHotspot && isActive ?"border-gray-200 border-[1px] rounded-lg mr-2":""}`}>
      <div key={sceneId} id={scene.sceneName} onClick={toggleScene} className={`inline-block w-[300px] rounded-lg mr-4 hover:cursor-pointer hover:bg-gray-200 ${isActive ? "border-[2px] border-blue-600":"border-[0.5px] border-gray-400"}`}>
        <div>
          {loading && <div className='absolute w-[300px] h-[160px] animate-pulse bg-gradient-to-tl from-black to-white rounded-t-lg'></div>}
          <img className='w-full h-[160px] object-cover rounded-t-lg' onLoad={()=>setLoading(false)} src={imageUrl}></img>
          <div className='px-2 mt-1 font-medium text-sm flex items-center justify-between'>{scene.sceneName}
            <div className='flex justify-center'>
              <button className='p-1 rounded hover:bg-slate-400' id="showHotspot" title='Hotspot Menu' onClick={toggleScene}><TfiAlignJustify onClick={toggleScene} className='w-5 h-5' id="showHotspot"/></button>
              <button className='p-1 rounded hover:bg-slate-400' id={sceneId} title='deleteScene' onClick={deleteScene}><TfiTrash className='w-5 h-5'/></button>
            </div>
          </div>
        </div>
      </div>
      {showHotspot && isActive && <Hotspots sceneId={sceneId}/>}
    </div>
  ) 
}

export default Scenes