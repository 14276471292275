export const formatTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const postTime = new Date(timestamp);
    const timeDifference = (currentTime - postTime) / 1000; // in seconds
  
    if (timeDifference < 60) {
      return `${Math.floor(timeDifference)} seconds ago`;
    } else if (timeDifference < 3600) {
      return `${Math.floor(timeDifference / 60)} minutes ago`;
    } else if (timeDifference < 86400) {
      return `${Math.floor(timeDifference / 3600)} hours ago`;
    } else if (timeDifference < 604800) {
      return `${Math.floor(timeDifference / 86400)} days ago`;
    } else if (timeDifference < 2592000) { // assuming 30 days in a month
      return `${Math.floor(timeDifference / 604800)} weeks ago`;
    } else if (timeDifference < 31536000) { // assuming 365 days in a year
      return `${Math.floor(timeDifference / 2592000)} months ago`;
    } else {
      return `${Math.floor(timeDifference / 31536000)} years ago`;
    }
  }
  export function getImageFromBinaryArray(image){
    var binary = '';
    var bytes = new Uint8Array(image);
    var len = bytes.byteLength;
    for (var i =0; i < len; i++){
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}