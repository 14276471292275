// src/components/Panorama.js
import React, { useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { VRButton, XR } from '@react-three/xr';
import { HiShare } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Panorama from './Panorama';

const PanoScene = () => {
  const {id} = useParams() 
  const [shareWindow,setShareWindow] = useState(false)
  useEffect(()=>{
    if(shareWindow){
      const span1 = document.querySelector("#designerLink");
      const span2 = document.querySelector("#userLink");
      span1.onclick = function() {
        document.execCommand("copy");
      }
      span1.addEventListener("copy", function(event) {
        event.preventDefault();
        if (event.clipboardData) {
          event.clipboardData.setData("text/plain", span1.textContent);
          console.log(event.clipboardData.getData("text"))
          setShareWindow(false)
          toast.success("Copied to Clipboard")
        }
      })
      span2.onclick = function() {
        document.execCommand("copy");
      }
      span2.addEventListener("copy", function(event) {
        event.preventDefault();
        if (event.clipboardData) {
          event.clipboardData.setData("text/plain", span2.textContent);
          console.log(event.clipboardData.getData("text"))
          setShareWindow(false)
          toast.success("Copied to Clipboard")
        }
      })
    }
  },[shareWindow])
  return (
    <>
    <div className='absolute w-screen flex items-center justify-end z-20 p-4'>
      <button onClick={()=>{setShareWindow(!shareWindow)}}><HiShare className='text-gray-200 w-8 h-8 '/></button>
      {shareWindow && <div className='absolute top-[17%] py-4 px-4 rounded-lg bg-white text-black z-20'>
                <p className='text-center text-lg font-bold'>Click to Copy Link</p>
                <p className='font-light p-1'>Designer Preview Link</p>
                <p id='designerLink' className='border-2 w-52 overflow-scroll sm:w-full px-2 py-1 font-mono text-xs rounded-lg hover:bg-gray-200'>{window.location.origin+"/tour/"+id}</p>
                <p className='font-light pt-2 p-1'>Users Link</p>
                <p id='userLink' className='border-2 w-52 overflow-scroll sm:w-full px-2 py-1 rounded-lg font-mono text-xs hover:bg-gray-200'>{"https://user.designalley.tech/tour/"+id}</p>
            </div>}
      {/* <VRButton className='absolute h-10 top-4 left-0'/> */}
    </div>
    <Canvas>
        <Panorama />
    </Canvas>
    </>
  );
};

export default PanoScene;
