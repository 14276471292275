import AWS from "aws-sdk"
import toast from "react-hot-toast"
import Resizer from "react-image-file-resizer"

const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_REGION
const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME
const BUCKET_NAME_RESIZED = process.env.REACT_APP_BUCKET_NAME_RESIZED
const BUCKET_NAME_MAPS = process.env.REACT_APP_BUCKET_NAME_MAPS

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3({
  region: REGION,
});


export const uploadImageToS3 = async (file,key,setProgress) => {
  const params = {
      Bucket: BUCKET_NAME,
      Key: key,
      Body: file,
    };
  var upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
      setProgress(parseInt((evt.loaded * 100) / evt.total))})
      .promise();
    upload.then(()=>{
      uploadResized(file,key)
    })
};
const uploadResized = async (file,key) => {
  const resized_file = await resizeFile(file);
  const params = {
      Bucket: BUCKET_NAME_RESIZED,
      Key: key,
      Body: resized_file,
    };
  var upload = s3.putObject(params).promise();
    upload.then(()=>{
      toast.success("Image Uploaded")
    })
};
function encode(data){
    var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
}
export const getImageFromS3  = async (key,resized=false) => {
  try{
    const getObjectParams = {
      Bucket: resized ? BUCKET_NAME_RESIZED : BUCKET_NAME,
      Key:key, 
      ResponseCacheControl: `public, max-age=604800`,
    };
    const res = await s3.getObject(getObjectParams).promise()
    return "data:image/png;base64," + encode(res.Body)
  } catch (err) {
    return ""
  }
}
export const getMapFromS3  = async (key) => {
  try{
    const getObjectParams = {
      Bucket: BUCKET_NAME_MAPS,
      Key:key, 
    };
    const res = await s3.getObject(getObjectParams).promise()
    return "data:image/png;base64," + encode(res.Body)
  } catch (err) {
    return ""
  }
}
export const uploadMapToS3 = async (file,key) => {
  const params = {
      Bucket: BUCKET_NAME_MAPS,
      Key: key,
      Body: file,
    };
  var upload = await s3.putObject(params).promise();
};
export const deleteImageFromS3  = async (key) => {
  try{
    const delete1 = {
      Bucket: BUCKET_NAME_RESIZED,
      Key:key
    };
    const delete2 = {
      Bucket: BUCKET_NAME,
      Key:key
    };
    const delete3 = {
      Bucket: BUCKET_NAME_MAPS,
      Key:key
    };
    await s3.deleteObject(delete1).promise()
    await s3.deleteObject(delete2).promise()
    await s3.deleteObject(delete3).promise()
    return true
  } catch (err) {
    console.log(err)
  }
}
export const resizeFile = (file) => new Promise((resolve) => {Resizer.imageFileResizer(file,1000,300,"JPEG",100,0,(uri) => {resolve(uri);},"file");});