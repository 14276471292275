import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'

function Layout(props) {
  return (
    <div className='w-screen h-screen'>
        <Header/>
        <div className='flex h-full'>
            <Sidebar/>
            <div className='overflow-y-scroll w-full'>
              {React.cloneElement(props.children)}
            </div>
        </div>
    </div>
  )
}


export default Layout