import { configureStore } from "@reduxjs/toolkit";
import { userReducers } from "./user";
import { globalReducers } from "./global";
import { designerReducers } from "./designer";
import { tourReducers } from "./tour";
import { formReducers } from "./form";
import { hotspotReducers } from "./hotspot";

export const store = configureStore(
    {
        reducer: {
            user:userReducers,
            designer:designerReducers,
            global:globalReducers,
            tour:tourReducers,
            form:formReducers,
            hotspot:hotspotReducers
        }
    }
)