import toast from "react-hot-toast"
import { API } from "../../Lib/API"
import { tourActions } from "../../Store/tour"
import CryptoJS from "crypto-js"
import { formActions } from "../../Store/form"
export const loadTour = async (id,dispatch)=>{
    await API.get('/tour/'+id).then((res)=>{
        if(res.status == 200){
            dispatch(tourActions.updateTour(res.data))
        }
    }).catch((err)=>{
      toast(err.message)
    })
}

export const updateTour = async (id,tour,dispatch)=>{
    await API.put('/tour/'+id,tour).then((res)=>{
        dispatch(tourActions.updateTour(res.data))
        dispatch(formActions.setUpdated(false))
    }).catch((err)=>{
      toast(err.message)
    })
}
export function generateKey(){
    var salt = CryptoJS.lib.WordArray.random(4);
    return CryptoJS.PBKDF2('DesignAlley', salt, { keySize: 4, iterations: 1 }).toString();     
  }