import { Fragment } from "react";
import { Route, createRoutesFromElements } from "react-router-dom";
import Auth from "./Components/Auth";
import Profile from "./Components/Proflie/Profile";
import Scene from "./Components/Scene";
import Editor from "./Components/Tour/Editor/Editor";
import Layout from "./Components/Layout";
import Groups from "./Components/Groups";
import Group from "./Components/Group";

export const routes = createRoutesFromElements(
    <Fragment>
        <Route path="/" element={<Auth />}>
            <Route path="home" element={<Layout><Profile /></Layout>} />
            <Route path="groups" element={<Layout><Groups /></Layout>} />
            <Route path="group/:id" element={<Layout><Group /></Layout>} />
            <Route path="tour/designer/:id" element={<Editor/>} />
            <Route path="tour/:id" element={<Scene/>} />
        </Route>
    </Fragment>
)