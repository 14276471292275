import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getDesigner, isVerifed } from '../Utils/HomeUtils';
import { globalActions } from '../Store/global';
import GettingStarted from './GettingStarted';
import { Toaster } from 'react-hot-toast';
import Login from './Login';
import { initOTPless } from '../Utils/InitOtpless';
import { CircleLoader } from 'react-spinners';
import Layout from './Layout';
import ComingSoon from './ComingSoon';

function Auth() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const global = useSelector(state => state.global)
  const location = useLocation()
  useEffect(() => {
    dispatch(globalActions.setLoading(false))
    localStorage.setItem('redirect',location.pathname)
    isVerifed(global,dispatch)
  }, []);
  useEffect(() => {
    initOTPless(handleUserData)
  }, [])
	const handleUserData = (otplessUser) => {
		localStorage.setItem("otpless",JSON.stringify(otplessUser))
    isVerifed(global,dispatch)
	}
  useEffect(()=>{
    if(global?.phoneNumber && global?.verified){
      getDesigner(global,dispatch,navigate)
    }
  },[global?.phoneNumber,global?.verified])

  return (
    <div >
      <Toaster/>
        {/* {global?.verified && global?.loading && <CircleLoader />}
        {global?.verified && global?.gettingStarted && <GettingStarted />}
        {!global?.gettingStarted && (global?.auth ? <Outlet/> : <Login/>)} */}
        <ComingSoon/>
    </div>
  )
}

export default Auth