import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from '../Lib/API'
import TourView from './Proflie/TourView'
import { getImageFromBinaryArray } from '../Utils/GlobalUtils'
import { BsBuilding } from 'react-icons/bs'

function Group() {
    const { id } = useParams()
    const [group,setGroup] = useState({})
    const navigate = useNavigate()
    useEffect(()=>{
        getGroup()
    },[])
    async function getGroup(){
        await API.get("/groups/"+id).then(res => {
            setGroup(res.data)
        })
    }
  return (
    <div className='w-full justify-center flex flex-col items-center'>
      <div className="w-full flex justify-around my-2 max-w-screen-lg" >
        <div onClick={()=>navigate("/home")} className='flex items-center justify-around'>
            {group.imageName? <img className='w-24 h-24 rounded-full shadow' src={"data:image/png;base64, " + getImageFromBinaryArray(group?.imageName?.data)}></img> :<BsBuilding className='w-24 h-24 rounded-full shadow'/>}
            <p className='text-3xl p-4 mt-4'>{group?.groupName}</p>
        </div>
    </div>
      <div className=' w-full max-w-screen-md h-full border-t-2'>
        <div className='sm:grid sm:grid-cols-2'>
            {group && group?.tours?.map((tour,id)=>{
                return (
                    <TourView tourId={tour}/>
                )
            })}
        </div>
      </div>
    </div>
  )
}

export default Group