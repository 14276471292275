import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Html, OrbitControls, Sphere, useTexture } from '@react-three/drei';
import * as THREE from "three"
import image360 from '../../../Assets/output.png';
import { CiCircleChevUp, CiCircleInfo } from 'react-icons/ci';
import { formActions } from "../../../Store/form";
import { hotspotActions } from "../../../Store/hotspot";
import { getImageFromS3 } from "../../../Utils/S3Utils";
const Panorama = () => {
    const ref = useRef();
    const form = useSelector(state => state.form)
    const tour = useSelector(state => state.tour)
    const [imageUrl,setImageUrl] = useState(image360)
    const hotspots = tour?.scenes[form.activeScene]?.hotspots
    const hotspot = useSelector(state => state.hotspot)
    const dispatch = useDispatch()
    const [point,setPoint] = useState({})
    const texture = useTexture(imageUrl);
    useEffect(()=>{
     setPoint({
        x:hotspot.x,
        y:hotspot.y,
        z:hotspot.z
      })
    },[hotspot])
    useEffect(()=>{
      if(form.activeScene!=-1){
        loadTexture()
      }
    },[form.activeScene])
    const onPointerMove = (e) => {
        if(form.editHotspot){
          setPoint({x:e.point.x,y:e.point.y,z:e.point.z})
        }
    }
    const handleClick = (e) => {
      if(form.editHotspot){
        dispatch(hotspotActions.setXYZ({x:e.point.x,y:e.point.y,z:e.point.z}))
        dispatch(formActions.setEditHotspot(false))
        dispatch(formActions.setUpdated(true))
      }
    }  
    const handleHotspotClick = (e) => {
      const linkId = e.target.id
      tour.scenes.map(async (scene,id) => {
        if(linkId == scene.sceneName){
          dispatch(formActions.setActiveScene(id))
          dispatch(formActions.setActiveHotspot(-1))
        }
      })
    }
    const loadTexture = async () => {
      const url = await getImageFromS3(tour.scenes[form.activeScene].imageName)
      setImageUrl(url)
    }
    return (
    <>
      <Sphere onClick={handleClick} onPointerMove={onPointerMove} ref={ref} args={[50,50,50]} scale={[1, 1, -1]}>
        <meshBasicMaterial map={texture} side={THREE.BackSide} />
      </Sphere>
      {hotspots && hotspots.map((spot,id) => {
        return (form.activeHotspot != id ? <Html key={id} position={[spot.x,spot.y,spot.z]}>
          {spot.type == 'Arrow'?
          <div className="flex flex-col">
          <CiCircleChevUp id={spot.link} onClick={handleHotspotClick} className='w-9 h-9 bg-white rounded-full hover:scale-150 transition-all peer' />
          <p className="hidden peer-hover:block text-[28px] text-white font-bold ">{spot.hoverText}</p> 
        </div> :
          <div className="flex flex-col">
            <CiCircleInfo className='w-9 h-9 bg-white rounded-full hover:scale-150 transition-all peer' />
            <p className="hidden peer-hover:block text-[28px] text-white font-bold">{spot.hoverText}</p> 
          </div> }
        </Html> : 
        <Html key={id} className='m-4' position={[point.x,point.y,point.z]}>
         {hotspot.type == 'Arrow'?
          <div className="flex flex-col">
          <CiCircleChevUp id={hotspot.link} onClick={handleHotspotClick} className='w-9 h-9 bg-white rounded-full hover:scale-150 transition-all peer' />
          <p className="hidden peer-hover:block text-[28px] text-white font-bold ">{hotspot.hoverText}</p> 
        </div> :
          <div className="flex flex-col">
            <CiCircleInfo className='w-9 h-9 bg-white rounded-full hover:scale-150 transition-all peer' />
            <p className="hidden peer-hover:block text-[28px] text-white font-bold">{hotspot.hoverText}</p> 
          </div> }
        </Html>)
      })}
      <OrbitControls enableZoom={false}/>
    </>
    );
};
export default Panorama