import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API } from '../../Lib/API'

import Posts from './Posts'
import Tours from './Tours';
import { useSelector } from 'react-redux';
function Profile() {
  const [imageUrl,setImageUrl] = useState("")
  const designer = useSelector(state => state.designer)
  const [menu,setMenu] = useState({
    tours:false,
    posts:true
  })
  const navigate = useNavigate()
  function decode_byteArray(result){
    var binary = '';
    var bytes = new Uint8Array(result);
    var len = bytes.byteLength;
    for (var i =0; i < len; i++){
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);

}
  return (
    <div className='w-full justify-center flex flex-col items-center'>
      <div className="w-full flex justify-around my-2 max-w-screen-lg" >
        <div onClick={()=>navigate("/home")} className='flex flex-col items-center justify-center overflow-hidden'>
          <img className='w-20 rounded-full' src={`data:image/png;base64, ${decode_byteArray(designer?.image?.data)}`}></img>
          <p className='text-sm mt-4'>{designer?.fullName}</p>
        </div>
        <div className='flex flex-col justify-between'>
          {/* <div className='flex w-full pt-2'>
          <button className="bg-[#be9682] h-min flex gap-1 items-center text-xs justify-center py-1 px-2 rounded mr-4">
          <CiEdit className='w-5 h-5'/>Edit Profile</button>
          <button className="bg-[#be9682] h-min flex gap-1 items-center text-xs justify-center py-1 px-2 rounded"><CiShare1 className='w-5 h-5'/>Share Profile</button>
          </div>
          <div className='flex w-full justify-evenly text-center m-2'>
            <div>
              <p className='mb-3'>10</p>
              <p>Posts</p>
            </div>
            <span className='border-l-[0.5px] border-black'></span>
            <div>
              <p className='mb-3'>1000</p>
              <p>Followers</p>
            </div>
            <span className='border-l-[0.5px] border-black'></span>
            <div>
              <p className='mb-3'>123</p>
              <p>Following</p>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className='flex'>
        {menu.posts ? <button className="bg-gray-300 text-black flex items-center justify-center py-4 w-full">
          <CiImageOn className='w-7 h-7'/>Posts</button> : <button onClick={()=>{setMenu({posts : true,tours:false})}}  className="bg-white text-black flex items-center justify-center py-4 w-full ">
          <CiImageOn className='w-7 h-7'/>Posts</button>}
          <br></br>
        {menu.tours ? <button className="bg-gray-300 text-black flex items-center justify-center py-4 w-full ">
        <TbRotate360 className='w-7 h-7'/>Tours</button> : <button onClick={()=>{setMenu({posts : false,tours:true})}}  className="bg-white text-black flex items-center justify-center py-4 w-full ">
        <TbRotate360 className='w-7 h-7'/>Tours</button>}
      </div> */}
      <div className='m-5 w-full max-w-screen-md h-full border-t-2'>
        {menu.posts && <Tours />}
        {/* {menu.tours && <Tours />} */}
      </div>
    </div>
  )
}

export default Profile