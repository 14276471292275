import { createSlice } from "@reduxjs/toolkit";


const initalState = {
    phoneNumber:"",
    fullName:"",
    email:""
}

const userSlice = createSlice({
    name:"user",
    initialState:initalState,
    reducers:{
        setPhoneNumber(state,action){
            return {
                ...state,
                phoneNumber:action.payload
            }
        },
        setFullName(state,action){
            return {
                ...state,
                fullName:action.payload
            }
        },
        setEmail(state,action){
            return {
                ...state,
                email:action.payload
            }
        },
    }
})

export const userReducers = userSlice.reducer
export const userActions = userSlice.actions