import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    loading : false,
    auth:false,
    gettingStarted:false,
    verified:false,
    phoneNumber:""
}

const globalSlice = createSlice({
    name:"global",
    initialState:initalState,
    reducers:{
        setLoading(state,action){
            return {
                ...state,
                loading:action.payload
            }
        },
        setAuth(state,action){
            return {
                ...state,
                auth:action.payload
            }
        },
        setPhoneNumber(state,action){
            return {
                ...state,
                phoneNumber:action.payload
            }
        },
        setVerified(state,action){
            return {
                ...state,
                verified:action.payload
            }
        },
        setGettingStarted(state,action){
            return{
                ...state,
                gettingStarted:action.payload
            }
        }
    }
})

export const globalReducers = globalSlice.reducer

export const globalActions = globalSlice.actions