import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tourActions } from '../../../Store/tour'
import { generateKey,updateTour } from '../TourUtils'
import { useParams } from 'react-router-dom'
import { SlCloudUpload } from 'react-icons/sl'
import Scenes from './Scenes'
import { getMapFromS3, uploadImageToS3 } from '../../../Utils/S3Utils'
import toast from 'react-hot-toast'
import { formActions } from '../../../Store/form'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'
import { CiCirclePlus, CiCircleRemove } from 'react-icons/ci'
function Form() {
    const { id } = useParams()
    const [sceneName,setSceneName] = useState("")
    const [progress,setProgress] = useState(0)
    const [file,setFile] = useState(null)
    const [showUpload,setShowUpload] = useState(false)
    const tour = useSelector(state => state.tour)
    const form = useSelector(state => state.form)
    const hotspot = useSelector(state => state.hotspot)
    const [mapImage,setMapImage] = useState(null)
    const dispatch = useDispatch()
    
    useEffect(()=>{
      if(progress == 100){
        updateTour(id,tour,dispatch)
        setProgress(0)
        setFile(null)
        setSceneName("")
        setShowUpload(false)
        dispatch(formActions.reset())
      } else {
        const progressBar = document.getElementById('progress')
        if(progress){
          progressBar.style.width = `${progress}%`
        }
      }
    },[progress])

    useEffect(()=>{
      (async () => {
        if(form.updated){
          dispatch(formActions.setUpdating(true))
          dispatch(formActions.setActiveHotspot(-1))
          await updateTour(id,tour,dispatch)
        }
      })()
    },[tour])
    useEffect(()=>{
      if(form.updating){
        setTimeout(()=>{
          dispatch(formActions.setUpdating(false))
        },2000)
      }
    },[form.updating])
    useEffect(()=>{
      if(tour?.scenes[form.activeScene]?.mapTagged ){
        dispatch(formActions.setMapTagged(true))
        updateMap()
      } else {
        dispatch(formActions.setMapTagged(false))
      }
    },[form.activeScene])
    async function updateMap(){
      const url = await getMapFromS3(tour?.scenes[form.activeScene]?.imageName)
      setMapImage(url)
    }
    const uploadImage = () => {
      if(file == null){
        toast.error("Upload file")
      } else if(sceneName == ""){
        document.getElementById("sceneName").focus()
        toast.error("Enter Scene Name")
      } else {
        dispatch(formActions.setUploading(true))
        const scene = {
          imageName:generateKey()+".webp",
          sceneName:sceneName,
          hotspots:[]
        }
        dispatch(tourActions.addScene(scene))
        setShowUpload(false)
        uploadImageToS3(file,scene.imageName,setProgress)
      }
    }
    function saveScene(){
      const hotspotId = form.activeHotspot
      const sceneId = form.activeScene
      dispatch(tourActions.updateHotspot({sceneId,hotspotId,updatedHotspot:hotspot}))
    }
    const handleFileChange = async (e) => {
      const file = e.target.files[0];
      setFile(file)
    };
  return (
    <div className='absolute w-screen text-black bottom-0 font-extralight rounded-t-lg z-[99999999]'>
      {form.mapTagged && <img className={form.showForm ? 'pl-10 w-40 opacity-70' : 'pl-10 w-40 opacity-70 mb-[-50px]'} src={mapImage}></img>}
      {!form.showForm && 
        <div className='w-full flex justify-center'>
          <button onClick={()=>{dispatch(formActions.setShowForm(true))}} className='bg-blue-500 rounded-full px-4 py-2 flex items-center z-10 mb-10 text-white'>Scenes {form.showForm ? <IoArrowDown className='w-5 h-5 ml-2'/> : <IoArrowUp className='w-5 h-5 ml-2'/>}</button>
        </div>}
        {form.showForm && 
        <div className='bg-white p-4'>
            <div className='sticky top-0 flex items-center'>
                <button className='flex items-center' onClick={()=>{dispatch(formActions.setShowForm(false))}}><CiCircleRemove className='w-8 h-8'/></button>
                <span className='mx-2 text-gray-500'>Tour Name: <span className='text-blue-600 text-xl font-semibold'>{tour.tourName}</span></span>
                {form.uploading && <div className='relative bg-gray-400 h-6 w-[300px] rounded text-right font-black'>
                  <div id='progress' className={`bg-purple-600 animate-pulse h-full absolute rounded`}></div>
                  {progress}%
                </div>}
                {form.updated && <button onClick={saveScene} className='hover:bg-green-600 hover:text-white border-2 border-green-600 text-black h-min px-4 rounded-full'>Save</button>}
            </div>
            <div className='p-4 grid grid-flow-col lg:flex overflow-x-auto'>
                {tour?.scenes?.map((scene,sceneId)=>{
                      return <Scenes key={sceneId} {...{scene,sceneId,progress}}/>
                })}
                <div className='inline-block'>
                  {!showUpload && <button className='w-[300px] h-[200px] rounded-lg border-[1px] text-blue-800 border-blue-800 bg-blue-200 hover:transition-all hover:duration-200 hover:ease-in hover:bg-blue-600 text-lg flex flex-col items-center justify-center hover:text-white hover:font-bold' onClick={()=>{setShowUpload(true)}}><CiCirclePlus className='w-10 h-10'/>Add Scene</button>}
                  {showUpload && !form.uploading && <div className='w-[300px] h-[200px] bg-blue-200 flex flex-col justify-center items-center rounded-lg'>
                    <div className='flex flex-col mt-4 min-h-24 justify-center items-center w-full'>
                      <label className='flex py-1 mt-2 w-[280px] h-[40px] rounded-lg items-center justify-center bg-blue-500 font-extralight text-white hover:font-bold hover:bg-blue-500 hover:transition-all hover:duration-200 hover:ease-in' htmlFor='image_upload'><SlCloudUpload className='w-8 h-8 mr-2'/>
                      <input onChange={handleFileChange} id="image_upload" className='hidden' type='file'></input>{file?file.name:"Upload Image"}</label>
                      {file && <input className=' bg-blue-400 w-[280px] font-medium placeholder:font-extralight text-white placeholder:text-white p-2 mt-2 rounded-lg' placeholder='Enter Scene Name' value={sceneName} id='sceneName' onChange={(e)=>setSceneName(e.target.value)}></input>}
                    </div>
                    <div className='flex justify-between items-center w-60 h-full'>
                      <button hidden={form.uploading} className='px-4 py-1 rounded-lg border-[1px] hover:border-green-800 bg-green-500 hover:transition-all hover:duration-200 hover:ease-in hover:bg-green-600 text-white' onClick={()=>{uploadImage()}}>Confirm</button>
                      <button hidden={form.uploading} className='px-4 py-1 rounded-lg border-[1px] hover:border-red-800 bg-red-500 hover:transition-all hover:duration-200 hover:ease-in hover:bg-red-600 text-white' onClick={()=>{setShowUpload(false);setFile(null)}}>Cancel</button>
                    </div>
                  </div>}
                </div>
            </div>
          </div>}
    </div>
  )
}

export default Form