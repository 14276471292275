import { API } from "../Lib/API";
import { globalActions } from "../Store/global";
import { designerActions } from "../Store/designer";

const isWithinADay = (dateTimeString) => {
    const inputDate = new Date(dateTimeString);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference < 24;
}
export const isVerifed = (global,dispatch) => {
  const designer = JSON.parse(localStorage.getItem("otpless"))
  if(designer && designer?.timestamp && isWithinADay(designer?.timestamp)){
    dispatch(globalActions.setVerified(true))
    dispatch(globalActions.setPhoneNumber(designer.identities[0].identityValue))
  }
}
export const getDesigner = async (global,dispatch,navigate) => {
  const phoneNumber = global?.phoneNumber
  await API.get("/login/designer?phoneNumber=" + phoneNumber).then(res => {
    dispatch(globalActions.setAuth(true))
    if(res.data){
      const {phoneNumber, fullName ,email , image} = res.data
      if(phoneNumber && fullName && email && image){
        dispatch(globalActions.setGettingStarted(false))
        dispatch(designerActions.addDesigner(res.data))
        const redirect = localStorage.getItem("redirect")
        if(redirect == "/"){
          navigate("/home")
        } else {
          navigate(redirect)
        }
      } else {
        dispatch(designerActions.addDesigner(res.data))
        dispatch(globalActions.setGettingStarted(true))
      }
    } else {
      dispatch(designerActions.addDesigner(res.data))
      dispatch(globalActions.setGettingStarted(true))
    }
  })
  dispatch(globalActions.setLoading(false))
}