import { createSlice } from "@reduxjs/toolkit"

const initalState = {
    type: 'Info',
    hoverText: '',
    link: '',
    x: null,
    y: null,
    z: null
}

const hotspotSlice = createSlice({
    name:"hotspot",
    initialState:initalState,
    reducers:{
        setHotspot(state,action){
            return action.payload
        },
        updateHotspot(state,action){
            const { name , value} = action.payload
            return {
                ...state,
                [name]:value
            }
        },
        setType(state,action){
            return {
                ...state,
                type:action.payload
            }
        },
        setHoverText(state,action){
            return {
                ...state,
                hoverText:action.payload
            }
        },setLink(state,action){
            return {
                ...state,
                link:action.payload
            }
        },setXYZ(state,action){
            const {x , y ,z} = action.payload
            return{
                ...state,
                x:x,y:y,z:z
            }
        },reset(){
            return initalState
        }
    }
})

export const hotspotReducers = hotspotSlice.reducer
export const hotspotActions = hotspotSlice.actions