import Logo from "../Assets/logo-text.png"

export default function ComingSoon() {
  return (
    <div>
        <div className='border-b sticky shadow flex justify-between p-2 items-center'>
            <img className='h-16 bg-white rounded' src={Logo}></img>
        </div>
        <div className="h-screen flex items-center justify-center">
            <p className="text-center text-slate-800 text-3xl font-bold">Coming Soon</p>
        </div>
    </div>
  )
}
