import { useEffect, useState } from "react"
import { DotLoader } from "react-spinners"
import { getImageFromS3 } from "../../Utils/S3Utils";
import { API } from "../../Lib/API";
function TourView({tourId}){
    const [imageUrl,setImageUrl] = useState(null)
    const [loading,setLoading] = useState(true)
    const [tour,setTour] = useState({})
    async function getTour(){
        await API.get("/tour/"+tourId).then(res => {
            setTour(res.data)
        })
    }
    useEffect(()=>{
        getTour()
    },[tourId])
    useEffect(()=>{
        if(tour){
            (async() => {
                if(tour?.scenes && tour.scenes[0]?.imageName){
                    const key = tour.scenes[0]?.imageName
                    const image = await getImageFromS3(key,true)
                    setImageUrl(image)
                }
            })()
        }
    },[tour])

    return (
        <div className='rounded m-2 border-[1px] border-gray-200'>
            {loading && <div className='bg-white flex flex-col items-center justify-center z-10 h-32 rounded-t-3xl'>
                <DotLoader color="#be9682"/>
            </div>}
            <div className='justify-center w-full border-b-[1px] border-gray-200 relative'>
                {tour?.scenes && tour.scenes[0]?.imageName ? 
                <img src={imageUrl} onLoad={(e)=>{setLoading(false)}} style={{width: loading ? "0px" : "100%",height: loading ? "0px":"8rem"}} className='rounded-t w-full object-cover' loading='lazy' ></img> :
                <div style={{display: loading ? "hidden":"flex"}} onLoad={(e)=>{setLoading(false)}} className='items-center justify-center text-center flex flex-col'>
                    <img loading='lazy' style={{width: loading ? "0px" : "50%"}} src='https://www.hotelperfect.co.in/wp-content/uploads/2017/07/360-Virtual-Tour-Logo.png'></img>
                    <div className='text-sm'>Upload a image in the Editor</div> 
                </div>}
            </div> 
            <p className="px-2 mt-2">{tour.tourName}</p>
        </div>
    )
}
export default TourView