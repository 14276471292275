import React, { useState } from 'react'
import { hotspotActions } from '../../../Store/hotspot'
import { formActions } from '../../../Store/form'
import { useDispatch, useSelector } from 'react-redux'
import { tourActions } from '../../../Store/tour'
import { HiOutlinePlusCircle} from 'react-icons/hi2'
import { TfiAngleDown, TfiAngleUp, TfiTrash } from 'react-icons/tfi'
import { BarLoader } from 'react-spinners'
import { CiMap } from 'react-icons/ci'
import { uploadMapToS3 } from '../../../Utils/S3Utils'

function Hotspots({sceneId}) {
    const form = useSelector(state => state.form)
    const hotspot = useSelector(state => state.hotspot)
    const hotspots = useSelector(state => state.tour.scenes[sceneId].hotspots)
    const scenes = useSelector(state => state.tour.scenes)
    const [file,setFile] = useState(null)
    const isActive = sceneId == form.activeScene
    const dispatch = useDispatch()
    function toggleHotspot(spotId,hotspot){
      const toggle = (spotId == form.activeHotspot)? -1 : spotId
      dispatch(formActions.setActiveHotspot(toggle))
      dispatch(formActions.setEditHotspot(false))
      if(toggle == spotId){
        dispatch(hotspotActions.setHotspot(hotspot))
      } else {
        dispatch(hotspotActions.reset())
      }
    }
    function newHotspot(){
      const hotspot = {
        type:"Info",
        hoverText:"",
        link:"",
        x:0,
        y:0,
        z:100
      }
      dispatch(tourActions.addHotspot({sceneId,hotspot}))
    }
    function updateHotspot(e){
      dispatch(formActions.setUpdated(true))
      dispatch(hotspotActions.updateHotspot({name:e.target.name,value:e.target.value}))
    }
    function deleteHotspot(e){
      dispatch(formActions.setUpdated(true))
      dispatch(tourActions.deleteHotspot({sceneId,hotspotId:e.target.id}))
      dispatch(hotspotActions.reset())
    }
    function confirmPosition(e){
      const hotspotId = form.activeHotspot
      dispatch(tourActions.updateHotspot({ sceneId, hotspotId, updatedHotspot:hotspot }))
    }
    function handleMapChange(e){
      setFile(e.target.files[0])
      dispatch(formActions.setUpdated(true))
    }
    async function uploadMap(){
      await uploadMapToS3(file,scenes[sceneId].imageName)
      dispatch(tourActions.updateMapTagged({sceneId,mapTagged:true}))
      setFile(null)
    }
  return (
    <div className='w-[300px] h-[200px] pr-2 overflow-y-scroll'>
      <div className='w-full flex items-center justify-center mt-2'>
        {isActive && form.mapTagged && <label title='Update Map' htmlFor='mapUpload' className='flex items-center justify-center font-extralight rounded border-[1px] hover:text-white hover:bg-green-600 w-full p-2'><CiMap className='w-6 h-6 mr-2'/>{file?file?.name:"Update Map"}</label>}
        {isActive && !form.mapTagged && <label title='Attach Map' htmlFor='mapUpload' className='flex items-center justify-center font-extralight rounded border-[1px] hover:text-white hover:bg-blue-600 w-full p-2'><CiMap className='w-6 h-6 mr-2'/>{file?file?.name:"Attach Map"}</label>}
        {file && <button onClick={uploadMap} className='flex w-36 text-xs items-center justify-center font-bold rounded border-[1px] hover:text-white hover:bg-cyan-600 h-full ml-2 p-3'>Upload Map</button>}
        <input hidden onChange={handleMapChange} type='file' accept='file/image' id='mapUpload'/>
      </div>
      <BarLoader loading={form.updating} width='300px' height='6px' color="#be9682"/>
    {!form.updating && hotspots?.map((spot,spotId) => {
    return(
      <div key={spotId} className='border-2 rounded my-2 p-1 bg-white'>
        <div className='flex'>
          <button onClick={()=>toggleHotspot(spotId,spot)} className='hover:bg-gray-200 px-2 rounded w-full flex items-center justify-between'>{`Hotspot ${spotId+1}`}{spotId == form.activeHotspot ? <TfiAngleUp className='w-4 h-4' /> : <TfiAngleDown className='w-4 h-4'/>}</button>
          <button id={spotId} onClick={deleteHotspot} className='p-2 rounded-md first-letter: hover:bg-red-200'><TfiTrash id={spotId}/></button>
        </div>
        {spotId == form.activeHotspot && <div className='w-full p-2 border-t-[1px]'>
          <div>
            {hotspot.x == spot.x && !form.editHotspot && <button className='border-[1px] hover:bg-gray-400 border-gray-400 px-2 text-xs rounded-full' onClick={()=>dispatch(formActions.setEditHotspot(true))}>Edit Position</button>}
            {form.editHotspot && <p className='text-xs'>Click on the Scene to proceed</p>}
            {hotspot.x != spot.x && !form.editHotspot && <button id={spotId} className='border-[1px] hover:bg-green-400 border-green-400 px-2 text-xs rounded-full' onClick={confirmPosition}>Confirm Postition</button>}
          </div>
          <div className='w-full mb-1'>
            <label className='text-sm'>Select Type: <span className='text-red-500'>*</span></label>
            <select value={hotspot.type} onChange={(e)=>{updateHotspot(e)}} name='type' className='text-white font-light bg-indigo-500 hover:bg-indigo-600 px-2 py-1 rounded w-full'>
              <option key='info'>Info</option>
              <option key='arrow'>Arrow</option>
            </select>
          </div>
          {hotspot.type == 'Arrow' && <div className='mb-1 w-full'>
            <label className='text-sm'>Link:  <span className='text-red-500'>*</span></label>
            <select name="link" onChange={(e)=>updateHotspot(e)} value={hotspot.link} className='text-white font-light bg-indigo-500 hover:bg-indigo-600 px-2 py-1 rounded w-full'>
              <option key="none" className='text-black px-2 py-1 rounded-sm'>None</option>
              {scenes.map((otherScene,otherSceneId)=>{
                if(otherSceneId != sceneId){
                  return <option key={otherSceneId} className='text-black px-2 py-1 rounded-sm'>{otherScene.sceneName}</option>
                }
              })}
            </select>
          </div>}
          <div className='mb-1 w-full'>
            <label className='text-sm'>Hover Text: <span className='text-red-500'>*</span></label>
            <input name="hoverText" onChange={(e)=>updateHotspot(e)} value={hotspot.hoverText} className=' bg-transparent p-1 border-b-2 w-full'></input>
          </div>
        </div>}
    </div>)
  })}
    {!form.updating && <button onClick={newHotspot} className='flex p-2 my-2 justify-center w-full border-[1px] border-gray-500 rounded hover:border-gray-800 hover:bg-gray-200 hover:text-gray-800'><HiOutlinePlusCircle className='w-6 h-6 mx-2'/><p>Add hotspot</p></button>}
  </div>
  )
}

export default Hotspots