import { useEffect, useState } from "react"
import { DotLoader } from "react-spinners"
import { MdOutlineModeEdit } from "react-icons/md";
import { HiOutlineViewfinderCircle, HiShare } from "react-icons/hi2";
import { BiTrash } from "react-icons/bi";
import { API } from "../../Lib/API";
import toast from "react-hot-toast";
import { getImageFromS3 } from "../../Utils/S3Utils";
function Tour({tour,getTours}){
    const [imageUrl,setImageUrl] = useState(null)
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        (async() => {
            if(tour?.scenes && tour.scenes[0]?.imageName){
                const key = tour.scenes[0]?.imageName
                const image = await getImageFromS3(key,true)
                setImageUrl(image)
            }
        })()
    },[])

    async function deleteTour(){
        await API.delete("/tour/"+tour._id).then(res =>{
            toast.error("Tour " + tour.tourName +" Deleted")
            getTours()
        })
    }
    function copyClipboard(link){
        navigator.clipboard.writeText(link)
        toast.success("Copied to Clipboard")
    }
    return (
        <div className='rounded m-2 border-[1px] border-gray-200'>
            {loading && <div className='bg-white flex flex-col items-center justify-center z-10 h-32 rounded-t-3xl'>
                <DotLoader color="#be9682"/>
            </div>}
            <div className='justify-center w-full border-b-[1px] border-gray-200 relative'>
                {tour?.scenes && tour.scenes[0]?.imageName ? 
                <img src={imageUrl} onLoad={(e)=>{setLoading(false)}} style={{width: loading ? "0px" : "100%",height: loading ? "0px":"8rem"}} className='rounded-t w-full object-cover' loading='lazy' ></img> :
                <div style={{display: loading ? "hidden":"flex"}} onLoad={(e)=>{setLoading(false)}} className='items-center justify-center text-center flex flex-col'>
                    <img loading='lazy' style={{width: loading ? "0px" : "50%"}} src='https://www.hotelperfect.co.in/wp-content/uploads/2017/07/360-Virtual-Tour-Logo.png'></img>
                    <div className='text-sm'>Upload a image in the Editor</div> 
                </div>}
                <HiShare onClick={(e)=>copyClipboard("https://user.designalley.tech/tour/"+tour._id)} id="link" className="absolute bg-black z-40 hover:bg-gray-600 p-1 w-7 h-7 rounded right-2 text-white top-2"/>
            </div> 
            <div className='flex justify-between items-center text-center p-2'>
                <p>{tour.tourName}</p>
                <div className='flex'>
                    <a className='bg-[#222222] text-white rounded flex p-1 items-center mx-2' href={window.location.origin+"/tour/"+tour._id}><HiOutlineViewfinderCircle className="w-5 h-5"/></a>
                    <a className='bg-[#222222] text-white rounded flex p-1 items-center mx-2' href={window.location.origin+"/tour/designer/"+tour._id}><MdOutlineModeEdit className="w-5 h-5"/> </a>
                    <button onClick={deleteTour} className='bg-[#222222] text-white rounded flex p-1 items-center mx-2' ><BiTrash className="w-5 h-5"/> </button>
                </div>
            </div>
        </div>
    )
}
export default Tour