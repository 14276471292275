import React, { useEffect, useState } from 'react'
import Form from './Form.jsx'
import PanoScene from './PanoScene.jsx'
import { CgArrowLeft } from 'react-icons/cg'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loadTour } from '../TourUtils.js'
import { CircleLoader } from 'react-spinners'
import { getImageFromS3, getMapFromS3 } from '../../../Utils/S3Utils.js'
function Editor() {
  const navigate = useNavigate()
  const { id } = useParams()
  const tour = useSelector(state => state.tour)
  const form = useSelector(state => state.form)
  const [loading,setLoading] = useState(true)
  const [count,setCount] = useState(0)
  const dispatch = useDispatch()
  useEffect(()=>{
    loadTour(id,dispatch)
  },[])
  useEffect(()=>{
    if(!form.updated && tour.tourName){
      loadAllScenes()
    }
  },[tour])
  async function loadAllScenes(){
    if(!tour.scenes.length){
      setLoading(false)
    }
    tour.scenes.map(async (scene)=>{
      const hiRes = await getImageFromS3(scene.imageName,true)
      const loRes = await getImageFromS3(scene.imageName)
      if(scene.mapTagged){
        await getMapFromS3(scene.imageName)
      }
      if(hiRes && loRes){
        setCount(prev => {
          if(tour.scenes.length-1 == prev){
            setLoading(false)
          } 
          return prev+1
        })
      }
    })
  }
  if(loading){
    return (
    <div className='relative w-screen h-screen flex flex-col items-center justify-center'>
      <CircleLoader size={600} color="#de996e" loading/>
      <div className='absolute ml-[-14rem] text-center  mt-[-14rem]'>
        <p className='text-xl font-thin text-[#95674a] animate-pulse'>Loading Scenes</p>
        <p className='text-gray-700 animate-pulse text-2xl p-2'>( {count} / {tour.scenes.length})</p>
      </div>
    </div>)
  }
  return (
    <div className='relative h-screen w-screen overflow-hidden'>
      <button onClick={()=>navigate("/home")} className='absolute top-5 left-5 bg-white rounded-full z-[99999] p-1x flex items-center text-black'><CgArrowLeft className='w-8 h-8'/></button>
      <PanoScene />
      <Form />
    </div>
  )
}

export default Editor