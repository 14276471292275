import { useEffect, useState } from "react"
import { API } from "../Lib/API"
import { Modal } from "react-bootstrap"
import { HiX } from "react-icons/hi"
import { useSelector } from "react-redux"
import { getImageFromBinaryArray } from "../Utils/GlobalUtils"
import { BsBuilding } from "react-icons/bs"
import { DotLoader } from "react-spinners"
import Tour from "./Proflie/Tour"
import TourView from "./Proflie/TourView"
import toast from "react-hot-toast"

export function AddTourToGroup({groups,show,hide}) {
    const [groupName,setGroupName] = useState("")
    const designer = useSelector(state => state.designer)
    const [tours,setTours] = useState([])
    const [tourId,setTourId] = useState("")
    async function proceed(){
        if(groupName == ""){
            toast.error("Select Group")
            return
        } else if(tourId == ""){
            toast.error("Select Tour")
            return
        }
        await API.put("/groups/add",{
            groupName,
            tourId
        }).then(()=>{
            hide()
        })
    }
    const getTours = async () => {
        await API.get("/tour/designer/"+ designer?._id).then((response)=>{
            if(response.status == 200){
                setTours(response.data)
            } else {
                setTours([])
            }
        }).catch(err=>{
            console.log(err)
        })
     }
    useEffect(()=>{
        getTours();
    },[])
  return (
    <Modal show={show} centered>
        <div className='p-10 flex flex-col'>
            <p className='pb-2 text-center text-xl flex items-center justify-between'>Add Group <HiX onClick={hide}/></p>
            <select className="bg-gray-600 p-2 my-2 text-white rounded" onChange={(e)=>setGroupName(e.target.value)}>
                <option defaultValue="">Select Group</option>
                {groups.map((tour,id) => {
                    return (
                        <option value={tour.groupName}>{tour.groupName}</option>
                    )
                })}
            </select>
            {groups.map((group,id)=>{
                if(group.groupName == groupName){
                    return(      
                        <div key={id} className='flex items-center border  rounded p-2'>
                            {group.imageName? <img className='w-12 h-12' src={"data:image/png;base64, " + getImageFromBinaryArray(group?.imageName?.data)}></img> :<BsBuilding className='h-12 w-12'/>}
                            <p className='w-48 overflow-clip font-bold text-gray-800 text-xl pt-2 pl-2'>{group.groupName}</p>
                        </div>)
                }  
            })}
            <select className="bg-gray-600 p-2 my-2 text-white rounded" onChange={(e)=>setTourId(e.target.value)}>
                <option defaultValue="">Select Tour</option>
                {tours.map((tour,id) => {
                    return (
                        <option value={tour._id}>{tour.tourName}</option>
                    )
                })}
            </select>
            <div className='rounded m-2 border-[1px] border-gray-200'>
            {tours.map((tour,id)=>{
                if(tour._id == tourId){
                    return(
                        <TourView tourId={tourId}/>
                    )
                }
            })}
        </div>
            <button onClick={proceed} className='bg-green-400 mt-2 hover:bg-green-500 text-white p-2 rounded w-32' >Submit</button>
        </div>
    </Modal>
  )
}