import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    tourName:"",
    scenes:[],
    designer:""
}

const tourSlice = createSlice({
    name:"Virtual Tour",
    initialState:initalState,
    reducers:{
        updateTour(state,action){
            return action.payload
        },
        addScene(state, action) {
            const scene = action.payload;
            return {
                ...state,
                scenes: [...state.scenes, scene]
            };
        },updateMapTagged(state, action) {
            const { sceneId, mapTagged } = action.payload;
            return {
                ...state,
                scenes: state.scenes.map((scene, index) => {
                    if (index === sceneId) {
                        return {
                            ...scene,
                            mapTagged: mapTagged 
                        }
                    } else {
                        return scene;
                    }
                })
            };
        },
        removeSceneById(state,action){
            const id = action.payload
            return {
                ...state,
                scenes: state.scenes.filter((scene,idx) => {
                    return id != idx 
                })
            }
        },   
        addHotspot(state, action) {
            const { sceneId, hotspot } = action.payload;
            return {
                ...state,
                scenes: state.scenes.map((scene, index) => {
                    if (index === sceneId) {
                        return {
                            ...scene,
                            hotspots: [...scene.hotspots, hotspot]
                        };
                    } else {
                        return scene;
                    }
                })
            };
        },
        updateHotspot(state, action) {
            const { sceneId, hotspotId, updatedHotspot } = action.payload;
            console.log({ sceneId, hotspotId, updatedHotspot })
            return {
                ...state,
                scenes: state.scenes.map((scene, index) => {
                    if (index === sceneId) {
                        return {
                            ...scene,
                            hotspots: scene.hotspots.map((hotspot, hId) => {
                                if (hId === hotspotId) {
                                    return updatedHotspot
                                } else {
                                    return hotspot;
                                }
                            })
                        };
                    } else {
                        return scene;
                    }
                })
            };
        },
        deleteHotspot(state, action) {
            const { sceneId, hotspotId } = action.payload;
            return {
                ...state,
                scenes: state.scenes.map((scene, index) => {
                    if (index === sceneId) {
                        return {
                            ...scene,
                            hotspots: scene.hotspots.filter((hotspot, hId) => {
                                return hId != hotspotId
                            })
                        };
                    } else {
                        return scene;
                    }
                })
            };
        }                     
    }
})

export const tourReducers = tourSlice.reducer
export const tourActions = tourSlice.actions