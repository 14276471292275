import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getImageFromS3 } from '../Utils/S3Utils'
import { formActions } from '../Store/form'

function UserSceneCard({sceneId,scene}) {
    const [imageUrl,setImageUrl] = useState()
    const form = useSelector(state => state.form)
    const isActive = form.activeScene == sceneId
    const [loading,setLoading] = useState(true)
    const dispatch = useDispatch()
    useEffect(()=>{
        getSceneImage()
    },[])
    async function getSceneImage(){
        const url = await getImageFromS3(scene.imageName,true)
        setImageUrl(url)
        setLoading(false)
    }
  return (
    <div key={sceneId} id={scene.sceneName} onClick={()=>dispatch(formActions.setActiveScene(sceneId))} className={`inline-block w-[300px] rounded-lg mr-4 hover:cursor-pointer hover:bg-gray-200 ${isActive ? "border-[2px] border-blue-600":"border-[0.5px] border-gray-400"}`}>
        {loading && <div className='absolute w-[300px] h-[160px] animate-pulse bg-gradient-to-tl from-black to-white rounded-t-lg'></div>}
            <img className='w-full h-[160px] object-cover rounded-t-lg' src={imageUrl}></img>
            <div className='px-2 mt-1 font-medium text-sm flex items-center justify-between'>{scene.sceneName}</div>
    </div>
  )
}

export default UserSceneCard