import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    phoneNumber:"",
    fullName:"",
    email:"",
    image:""
}

const designerSlice = createSlice({
    name:"designer",
    initialState:initalState,
    reducers:{
        addDesigner(state,action){
            return action.payload
        }
    }
})

export const designerReducers = designerSlice.reducer
export const designerActions = designerSlice.actions