import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {HiArrowLeft, HiPhotograph, HiTrash} from 'react-icons/hi'
import { TbView360 } from "react-icons/tb";
import { API } from "../../Lib/API"
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux';
function Forms({getTours}) {
  const [newTour,setNewTour] = useState(false)
  const [tourName,setTourName] = useState("")
  const designer = useSelector(state => state.designer)
  const createNewTour = async () =>{
    const postBody = {
      tourName:tourName,
      designer:designer._id
  }
    await API.post('/tour/create',postBody).then((res)=>{
      if(res.status == 200){
        toast("Tour Created Successfully")
        setTourName('')
        setNewTour(false)
        getTours()
      }
      else{
        toast("Tour Already Exists")
        newTour(false)
        setTourName('')
      }
    }).catch((err)=>{
      toast("Tour Name already Exists")
      newTour(false)
      setTourName('')
    })
  }
  return (
    <div>
        <div className="justify-center items-center w-full my-2">
            <div className='flex w-full items-center justify-center'>
              {(newTour) && <button onClick={() => { 
                setNewTour(false)
                setTourName("")
              }} className='bg-gray-200 rounded-full p-2'>
                <HiArrowLeft className='w-7 h-7'/>
              </button>}
              {(!newTour) && <button onClick={() => {
                setNewTour(true) 
                }} className='bg-[#222222] w-full flex rounded-lg p-4 items-center justify-center text-white'>
                <TbView360 className='w-7 h-7 mr-5'/>Create a New Tour</button>
              }
              {newTour && <div className="flex w-full p-5 items-center border-b border-white py-2">
                  <input name='tourName' value={tourName} onChange={(e) => { setTourName(e.target.value) } } className="w-full text-black mr-3 py-2 px-4 leading-tight focus:outline-none border-b border-black" type="text" placeholder="Tour Name" />
                  <button className="flex-shrink-0 hover:bg-[#cf8763] bg-[#222222] text-white py-2 px-4 rounded" onClick={() => { createNewTour() } } type="button">
                    Create
                  </button>
                </div>}
            </div>
          </div>
    </div>
  )
}

export default Forms