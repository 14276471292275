import React, { useEffect, useState } from 'react'
import { AddTourToGroup } from './AddTourToGroup'
import { BsBuilding } from 'react-icons/bs'
import { API } from '../Lib/API'
import { getImageFromBinaryArray } from '../Utils/GlobalUtils'
import { useNavigate } from 'react-router-dom'

function Groups() {
    const [showForm,setShowForm]  = useState(false)
    const [groups,setGroups] = useState([])
    const navigate = useNavigate()
    useEffect(()=>{
        getGroups()
    },[])
    async function getGroups(){
        await API.get("/groups").then(res => {
            setGroups(res.data)
        })
    }
  return (
    <div className='w-full justify-center flex flex-col items-center'>
      <div className="w-full flex justify-around my-2 max-w-screen-lg border-b p-4" >
        <button onClick={()=>setShowForm(true)} className='btn-main'>Attach Tour</button>
        {showForm && <AddTourToGroup groups={groups} show={showForm} hide={()=>setShowForm(false)}/>}
      </div>
      <div className="w-full flex flex-col items-center my-2 max-w-screen-lg" >
        <p className='font-bold text-3xl'>Groups</p>
        <div className='grid gap-2'>
            {groups.map((group,id)=>{  
                return(      
                    <div key={id} onClick={()=>navigate("/group/"+group._id)} className='flex hover:bg-gray-100 cursor-pointer items-center border justify-between rounded p-2 w-80'>
                        {group.imageName? <img className='w-12 h-12' src={"data:image/png;base64, " + getImageFromBinaryArray(group?.imageName?.data)}></img> :<BsBuilding className='h-12 w-12'/>}
                        <p className='w-48 overflow-clip font-bold text-gray-800 text-xl pt-2 pl-2'>{group.groupName}</p>
                    </div>)
            })}
        </div>
      </div>
    </div>
  )
}

export default Groups