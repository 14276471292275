import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    activeScene:-1,
    activeHotspot:-1,
    uploading:false,
    updating:false,
    showForm:false,
    updated:false,
    editHotspot:false,
    mapTagged:false,
    point:{
        x:null,
        y:null,
        z:null
    }
}

const formSlice = createSlice({
    name:"form",
    initialState:initalState,
    reducers:{
        setActiveScene(state,action){
            return {
                ...state,
                activeScene:action.payload
            }
        },setActiveHotspot(state,action){
            return {
                ...state,
                activeHotspot:action.payload
            }
        },
        setUploading(state,action){
            return {
                ...state,
                uploading:action.payload
            }
        },
        setShowForm(state,action){
            return {
                ...state,
                showForm:action.payload
            }
        },
        setUpdated(state,action){
            return{
                ...state,
                updated:action.payload
            }
        },
        setUpdating(state,action){
            return{
                ...state,
                updating:action.payload
            }
        },
        setEditHotspot(state,action){
            return{
                ...state,
                editHotspot:action.payload,
            }
        },
        setPoint(state,action){
            return{
                ...state,
                point:action.payload
            }
        },
        setMapTagged(state,action){
            return {
                ...state,
                mapTagged:action.payload
            }
        },
        reset(){
            return initalState
        }
    }
})

export const formReducers = formSlice.reducer
export const formActions = formSlice.actions