import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function Sidebar() {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <div className='flex flex-col w-60 p-4 shadow-inner h-full'>
        <button onClick={()=>{navigate("/home")}} className={location.pathname == '/home'?'btn-sidebar-active':'btn-sidebar'}>Home</button>
        <button onClick={()=>{navigate("/groups")}} className={location.pathname == '/groups'?'btn-sidebar-active':'btn-sidebar'}>Groups</button>
    </div>
  )
}
export default Sidebar