import React, { useEffect, useState } from 'react'
import { API } from '../../Lib/API'
import toast from 'react-hot-toast'
import { DotLoader } from 'react-spinners'
import { MdOutlineModeEdit } from "react-icons/md";
import { HiOutlineViewfinderCircle } from "react-icons/hi2";
import Forms from '../Tour/Forms';
import { useSelector } from 'react-redux';
import { BiTrash } from 'react-icons/bi';
import Tour from './Tour';
function Tours() {
    const [tours,setTours] = useState([])
    const [tourId,setTourId] = useState(null)
    const designer = useSelector(state => state.designer)
    const getTours = async () => {
        await API.get("/tour/designer/"+ designer?._id).then((response)=>{
            if(response.status == 200){
                setTours(response.data)
            } else {
                setTours([])
            }
        }).catch(err=>{
            console.log(err)
        })
     }
    useEffect(()=>{
        getTours();
    },[])
  return (
    <div>
        <Forms {...{getTours}}/>
        <div className='sm:grid sm:grid-cols-2'>
            {tours.map((tour,id) => {
                return <Tour key={id} {...{tour,getTours}}/>
            })}
        </div>
    </div>
  )
}

export default Tours