import React, { useEffect, useState } from 'react';
import * as PANOLENS from 'panolens';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadTour } from './Tour/TourUtils';
import { formActions } from '../Store/form';
import { getImageFromS3, getMapFromS3 } from '../Utils/S3Utils';
import { CircleLoader } from 'react-spinners';
import { CiCircleRemove } from 'react-icons/ci';
import { IoArrowDown } from 'react-icons/io5';
import UserSceneCard from './UserSceneCard';

const Scene = () => {
    const { id } = useParams()
    const tour = useSelector(state => state.tour)
    const form = useSelector(state => state.form)
    const [panorama,setPanorama] = useState(new PANOLENS.ImagePanorama())
    const [loading,setLoading] = useState(true)
    const [count,setCount] = useState(0)
    const [imageUrl,setImageUrl] = useState(null)
    const [mapImage,setMapImage] = useState(null)
    const dispatch = useDispatch()
    const [viewer,setViewer] = useState(null)
    useEffect(()=>{
        loadTour(id,dispatch)
    },[])
    useEffect(()=>{
        if(tour.tourName){
            loadAllScenes()
        }
    },[tour])
    async function loadAllScenes(){
        if(!tour.scenes.length){
            setLoading(false)
          }
        tour.scenes.map(async (scene)=>{
            const hiRes = await getImageFromS3(scene.imageName,true)
            const loRes = await getImageFromS3(scene.imageName)
            if(hiRes && loRes){
            setCount(prev => {
                if(tour.scenes.length-1 == prev){
                setLoading(false)
                } 
                return prev+1
            })
            }
        })
    }
    useEffect(()=>{
        if(!loading){
            const container = document.getElementById('panolens-container');
            const viewer = new PANOLENS.Viewer({ container,viewIndicator:true,enableReticle:true})
            viewer.add(panorama);
            setViewer(viewer)
            dispatch(formActions.setActiveScene(0))
        }
    },[loading])
    useEffect(()=>{
        getImageUrl()
        if(tour?.scenes[form.activeScene]?.mapTagged ){
            dispatch(formActions.setMapTagged(true))
            updateMap()
          } else {
            dispatch(formActions.setMapTagged(false))
          }
    },[form.activeScene])
    async function updateMap(){
        const url = await getMapFromS3(tour?.scenes[form.activeScene]?.imageName)
        setMapImage(url)
      }
    async function getImageUrl () {
        if(form.activeScene!=-1){
            const url = await getImageFromS3(tour.scenes[form.activeScene]?.imageName)
            setImageUrl(url)
        }
    } 
    function toggleFullscreen() {
        const fullScreenBtn = document.getElementById("panolens-container")
        if (!document.fullscreenElement) {
            viewer.enableControl(1)
            viewer.enableEffect(2)
            if(window.screen?.orientation?.lock){
                try{
                    window.screen.orientation.lock('landscape-primary')
                } catch (err) {
                    console.log(err)
                }
            }
            fullScreenBtn.requestFullscreen().catch((err) => {
            alert(
              `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
            );
          });
        } else {
            console.log(viewer.enableControl(0))
            console.log(viewer.enableEffect(2))
            document.exitFullscreen();
        }
      }
      useEffect(() => {
        if(imageUrl != ""){
            sceneLoader()
        }
    }, [imageUrl]);
    const sceneLoader = () => {
        panorama.children = []
        const allHotsopts = document.getElementsByClassName("panolens-infospot")
        while (allHotsopts.length > 0) {
            allHotsopts[0].parentNode.removeChild(allHotsopts[0]);
        }
        console.log("loaded")
        panorama.load(imageUrl)
        if(form.activeScene!=-1){
            tour.scenes[form.activeScene].hotspots.map((hotspot)=>{
                var tempSpot
                if(hotspot.type == "Arrow"){
                    tempSpot = new PANOLENS.Infospot(5, PANOLENS.DataImage.Arrow);
                }
                else{
                    tempSpot = new PANOLENS.Infospot(5, PANOLENS.DataImage.Info);
                }
                tempSpot.position.set(hotspot.x,hotspot.y,-hotspot.z);
                tempSpot.addHoverText(hotspot.hoverText)
                tempSpot.addEventListener('click',()=>{
                    tour.scenes.map((scene,id)=>{
                        if(hotspot.link == scene.sceneName){
                            dispatch(formActions.setActiveScene(id))
                        }
                    })
                })
                panorama.add(tempSpot); 
            })
        }
    }
    if(loading){
        return (
        <div className='relative w-screen h-screen flex flex-col items-center justify-center'>
          <CircleLoader size={600} color="#de996e" loading/>
          <div className='absolute ml-[-14rem] text-center  mt-[-14rem]'>
            <p className='text-xl font-thin text-[#95674a] animate-pulse'>Loading Scenes</p>
            <p className='text-gray-700 animate-pulse text-2xl p-2'>( {count} / {tour.scenes.length})</p>
          </div>
        </div>)
      }
  return <div>
    <a-scene>
            <div id="panolens-container" className="fixed z-[99999] w-screen h-screen"></div>
                <div onClick={toggleFullscreen} className='absolute z-[999999] top-0 w-screen flex items-center justify-center flex-col'>
                    <button className='px-10 py-2 mt-4 border-[2px] border-[#de996e] bg-[rgba(0,0,0,0.2)] rounded-3xl text-[#de996e] font-bold'>Enter VR</button>
                </div>
                <div className='absolute w-screen text-black bottom-0 font-extralight rounded-t-lg z-[99999999]'>
                {form.mapTagged && <img className={form.showForm ? 'pl-10 w-40 opacity-70' : 'pl-10 w-40 opacity-70 mb-[-50px]'} src={mapImage}></img>}
                {!form.showForm && 
                    <div className='w-full flex justify-center'>
                    <button onClick={()=>{dispatch(formActions.setShowForm(true))}} className='bg-blue-500 rounded-full px-4 py-2 flex items-center z-10 mb-10 text-white'>Scenes <IoArrowDown className='w-5 h-5 ml-2'/> </button>
                    </div>}
                {form.showForm && 
                <div className='bg-white p-4'>
                    <div className='sticky top-0 flex items-center'>
                        <button className='flex items-center' onClick={()=>{dispatch(formActions.setShowForm(false))}}><CiCircleRemove className='w-8 h-8'/></button>
                        <span className='mx-2 text-gray-500'>Tour Name: <span className='text-blue-600 text-xl font-semibold'>{tour.tourName}</span></span>
                    </div>
                <div className='p-4 grid grid-flow-col lg:flex overflow-x-auto'>
                    {tour?.scenes?.map((scene,sceneId)=>{
                        return (
                            <UserSceneCard scene={scene} sceneId={sceneId}/>
                        )
                    })}
                </div>
                </div>}
            </div>
            <a-camera>
                <a-cursor/>
            </a-camera>
            </a-scene>
        </div>
}

export default Scene;
