import React from 'react'
import Logo from "../Assets/logo-text.png"
import {FaRegCircleUser} from "react-icons/fa6"
import { useNavigate } from 'react-router-dom'
function Header() {
    const navigate = useNavigate()
    function logout(){
        localStorage.removeItem('otpless')
        navigate(0)
    }
  return (
    <div className='border-b sticky shadow flex justify-between p-2 items-center'>
        <img className='h-16 bg-white rounded' src={Logo}></img>
        <div className='flex items-center w-40 justify-around'>
          <FaRegCircleUser  className='w-8 h-8 mt-2'/>
          <button className='btn-main w-20 font-light' onClick={logout}>Logout</button>
        </div>
    </div>
  )
}
export default Header